.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  color: rgb(39, 39, 45);
  width: 100%;
  height: 39px;
  font-size: 54px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 400;
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-image {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.home-hero-image {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #E5FE58;
}
.home-image1 {
  width: 640px;
  height: 540px;
  object-fit: cover;
}
.home-who {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.home-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.home-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-text02 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.home-text03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.home-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.home-image2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5FE58;
}
.home-image3 {
  object-fit: cover;
  margin-left: -160px;
}
.home-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.home-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  border-top-width: 1px;
}
.home-purpose {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-purpose1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption1 {
  color: rgb(39, 39, 45);
  align-self: flex-end;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-purpose2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption2 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-industries {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption3 {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-list {
  width: 100%;
  display: grid;
  grid-gap: 80px;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto auto;
}
.home-accordion {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text04 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text05 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text06 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text07 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text08 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text09 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text10 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text11 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text12 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text13 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text14 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text15 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text16 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text17 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text18 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text19 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text20 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text21 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text22 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text23 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title4 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text24 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text25 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text26 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text27 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text28 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title5 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text29 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text30 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text31 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text32 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text33 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title6 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text34 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text35 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text36 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text37 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text38 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-item6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-trigger7 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title7 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text39 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text40 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text41 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text42 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-text43 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.home-section {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 135px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 135px;
  background-color: #27272D;
}
.home-case-studies {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: 130px;
  border-bottom-width: 1px;
}
.home-caption4 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.home-text44 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-date-range {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text45 {
  color: rgb(255, 255, 255);
}
.home-text46 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 300;
}
.home-text47 {
  color: rgb(255, 255, 255);
}
.home-testimonials {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  flex-direction: column;
}
.home-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-caption5 {
  color: #ffffff;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-controls {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-icon {
  fill: #61616B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.home-text48 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
}
.home-icon2 {
  fill: #ffffff;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.home-row {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-quote1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-gradiant {
  top: 0px;
  right: 0px;
  width: 100px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  background-image: linear-gradient(90deg, rgba(39, 39, 45, 0) 3.00%,rgba(39, 39, 45, 0.5) 33.00%,rgb(39, 39, 45) 100.00%);
}
.home-book {
  width: 100%;
  height: 596px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.home-content1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text49 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.home-description03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-link {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.home-image4 {
  height: 400px;
  object-fit: cover;
}
.home-book1 {
  width: 100%;
  height: 682px;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.home-content2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text55 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.home-description04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-form {
  width: 585px;
  height: 231px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-textinput {
  width: 360px;
  height: 34px;
  background-color: rgba(217, 217, 217, 0);
  border-top-width: 0px;
  border-left-width: 00px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-container2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container3 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-button {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(0, 0, 0);
}
.home-image5 {
  height: 400px;
  object-fit: cover;
}
.home-section1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #244444;
}
.home-about-us {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.home-header3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption6 {
  color: #ffffff;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description05 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.home-statistics {
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #ffffff;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-statistic {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text60 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.home-text61 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.home-statistic1 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text62 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.home-text63 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.home-statistic2 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text64 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.home-text65 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.home-statistic3 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text66 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.home-text67 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.home-dna {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 140px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-caption7 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description06 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description07 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description08 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description09 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description10 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description11 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-description12 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-gallery {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: 120px;
}
.home-image6 {
  right: 0px;
  width: 640px;
  bottom: 0px;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-image7 {
  width: 760px;
  object-fit: cover;
}
.home-recruit {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container4 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text68 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  max-width: 1000px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.home-description13 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  align-self: flex-start;
  line-height: 54px;
}
.home-sections {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text74 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.home-text77 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.home-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text78 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.home-text81 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.home-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text82 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.home-text85 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.home-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text88 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.home-text89 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.home-description14 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.home-button1 {
  color: rgb(39, 39, 45);
  font-size: 24px;
  line-height: 30px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(229, 254, 88);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
@media(max-width: 991px) {
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text {
    font-size: 32px;
  }
  .home-image {
    display: none;
  }
  .home-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .home-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    gap: var(--dl-space-space-unit);
  }
  .home-text02 {
    font-size: 32px;
  }
  .home-text03 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-image2 {
    width: 80%;
    height: 100%;
  }
  .home-image3 {
    width: 100%;
  }
  .home-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .home-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .home-purpose {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption {
    font-size: 12px;
  }
  .home-description {
    font-size: 16px;
    line-height: 28px;
  }
  .home-purpose1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption1 {
    font-size: 12px;
  }
  .home-description01 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-purpose2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption2 {
    font-size: 12px;
  }
  .home-description02 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-caption3 {
    font-size: 12px;
  }
  .home-list {
    grid-gap: 16px;
    grid-template-columns: auto;
  }
  .home-accordion {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text04 {
    font-size: 16px;
  }
  .home-text05 {
    font-size: 16px;
  }
  .home-text06 {
    font-size: 16px;
  }
  .home-text07 {
    font-size: 16px;
  }
  .home-text08 {
    font-size: 16px;
  }
  .home-item {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title1 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text09 {
    font-size: 16px;
  }
  .home-text10 {
    font-size: 16px;
  }
  .home-text11 {
    font-size: 16px;
  }
  .home-text12 {
    font-size: 16px;
  }
  .home-text13 {
    font-size: 16px;
  }
  .home-item1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title2 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text14 {
    font-size: 16px;
  }
  .home-text15 {
    font-size: 16px;
  }
  .home-text16 {
    font-size: 16px;
  }
  .home-text17 {
    font-size: 16px;
  }
  .home-text18 {
    font-size: 16px;
  }
  .home-item2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title3 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text19 {
    font-size: 16px;
  }
  .home-text20 {
    font-size: 16px;
  }
  .home-text21 {
    font-size: 16px;
  }
  .home-text22 {
    font-size: 16px;
  }
  .home-text23 {
    font-size: 16px;
  }
  .home-item3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title4 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text24 {
    font-size: 16px;
  }
  .home-text25 {
    font-size: 16px;
  }
  .home-text26 {
    font-size: 16px;
  }
  .home-text27 {
    font-size: 16px;
  }
  .home-text28 {
    font-size: 16px;
  }
  .home-item4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title5 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text29 {
    font-size: 16px;
  }
  .home-text30 {
    font-size: 16px;
  }
  .home-text31 {
    font-size: 16px;
  }
  .home-text32 {
    font-size: 16px;
  }
  .home-text33 {
    font-size: 16px;
  }
  .home-item5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title6 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text34 {
    font-size: 16px;
  }
  .home-text35 {
    font-size: 16px;
  }
  .home-text36 {
    font-size: 16px;
  }
  .home-text37 {
    font-size: 16px;
  }
  .home-text38 {
    font-size: 16px;
  }
  .home-item6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-title7 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text39 {
    font-size: 16px;
  }
  .home-text40 {
    font-size: 16px;
  }
  .home-text41 {
    font-size: 16px;
  }
  .home-text42 {
    font-size: 16px;
  }
  .home-text43 {
    font-size: 16px;
  }
  .home-section {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-case-studies {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-caption4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text44 {
    font-size: 12px;
  }
  .home-date-range {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text45 {
    font-size: 12px;
  }
  .home-text46 {
    font-size: 12px;
  }
  .home-text47 {
    font-size: 12px;
  }
  .home-testimonials {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
  }
  .home-controls {
    display: none;
  }
  .home-row {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .home-left {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .home-text49 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .home-description03 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-image4 {
    height: 220px;
  }
  .home-book1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .home-left1 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .home-text55 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .home-description04 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-text59 {
    font-size: 16px;
  }
  .home-image5 {
    height: 220px;
  }
  .home-section1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-about-us {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption6 {
    font-size: 12px;
  }
  .home-description05 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-statistics {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text60 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-text61 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text62 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-text63 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text64 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-text65 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text66 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-text67 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-dna {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .home-caption7 {
    font-size: 12px;
  }
  .home-description06 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description07 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description08 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description09 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description10 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description11 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-description12 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-recruit {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
  }
  .home-text68 {
    font-size: 32px;
    line-height: 38px;
  }
  .home-description13 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-text71 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-sections {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-section2 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text74 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text77 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-section3 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text78 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text81 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-section4 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text82 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text85 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-section5 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text88 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text89 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-description14 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-text92 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-image1 {
    width: auto;
    height: 220px;
  }
  .home-who {
    margin-bottom: 0px;
  }
  .home-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .home-video1 {
    margin-top: 0px;
  }
  .home-image6 {
    width: 350px;
  }
  .home-image7 {
    width: 350px;
  }
}
@media(max-width: 479px) {
  .home-image3 {
    margin-left: -120px;
  }
  .home-video {
    bottom: -419px;
  }
  .home-image6 {
    width: 240px;
  }
  .home-image7 {
    top: 250px;
    width: 240px;
  }
}
