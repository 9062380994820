.computatrum-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.computatrum-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.computatrum-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text {
  color: #1b1b4c;
  width: 100%;
  height: 39px;
  font-size: 54px;
  max-width: 1000px;
  font-weight: 700;
}
.computatrum-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.computatrum-image {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.computatrum-hero-image {
  width: 100%;
  display: none;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #E5FE58;
}
.computatrum-image1 {
  width: 640px;
  height: 540px;
  object-fit: cover;
}
.computatrum-who {
  gap: 120px;
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.computatrum-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.computatrum-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.computatrum-text005 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.computatrum-text006 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.computatrum-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.computatrum-image2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5FE58;
}
.computatrum-image3 {
  object-fit: cover;
  margin-left: -160px;
}
.computatrum-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.computatrum-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  border-top-width: 1px;
}
.computatrum-description {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-purpose {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-caption {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-purpose1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-caption01 {
  color: rgb(39, 39, 45);
  align-self: flex-end;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-purpose2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-caption02 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-purpose3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-caption03 {
  color: rgb(39, 39, 45);
  align-self: flex-end;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-purpose4 {
  gap: var(--dl-space-space-twounits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-industries {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-caption04 {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-list {
  width: 100%;
  display: grid;
  grid-gap: 80px;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto auto;
}
.computatrum-accordion {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text015 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text016 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text017 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text018 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text019 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text020 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text021 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text022 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text023 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text024 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text025 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text026 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text027 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text028 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text029 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text030 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text031 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text032 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text033 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text034 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text035 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text036 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text037 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text038 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text039 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text040 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text041 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text042 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text043 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text044 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text045 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text046 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text047 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text048 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text049 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-item6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-trigger7 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-text050 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text051 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text052 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text053 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-text054 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computatrum-section {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 135px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 135px;
  background-color: #27272D;
}
.computatrum-case-studies {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: 130px;
  border-bottom-width: 1px;
}
.computatrum-caption05 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.computatrum-text055 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-date-range {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computatrum-text056 {
  color: rgb(255, 255, 255);
}
.computatrum-text057 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 300;
}
.computatrum-text058 {
  color: rgb(255, 255, 255);
}
.computatrum-testimonials {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  flex-direction: column;
}
.computatrum-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.computatrum-caption06 {
  color: #ffffff;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-controls {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.computatrum-icon {
  fill: #61616B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computatrum-text059 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
}
.computatrum-icon2 {
  fill: #ffffff;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computatrum-row {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.computatrum-quote1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computatrum-gradiant {
  top: 0px;
  right: 0px;
  width: 100px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  background-image: linear-gradient(90deg, rgba(39, 39, 45, 0) 3.00%,rgba(39, 39, 45, 0.5) 33.00%,rgb(39, 39, 45) 100.00%);
}
.computatrum-book {
  width: 100%;
  height: 777px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computatrum-content1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computatrum-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text060 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computatrum-description05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-link {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.computatrum-image4 {
  height: 400px;
  object-fit: cover;
}
.computatrum-book1 {
  width: 100%;
  height: 682px;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computatrum-content2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computatrum-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text064 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computatrum-description06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computatrum-form {
  width: 585px;
  height: 231px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-textinput {
  width: 360px;
  height: 34px;
  background-color: rgba(217, 217, 217, 0);
  border-top-width: 0px;
  border-left-width: 00px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.computatrum-container2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computatrum-container3 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computatrum-button {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(0, 0, 0);
}
.computatrum-image5 {
  height: 400px;
  object-fit: cover;
}
.computatrum-section1 {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #244444;
}
.computatrum-about-us {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-header3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-description07 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computatrum-statistics {
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #ffffff;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.computatrum-statistic {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computatrum-text069 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computatrum-text070 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computatrum-statistic1 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computatrum-text071 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computatrum-text072 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computatrum-statistic2 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computatrum-text073 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computatrum-text074 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computatrum-statistic3 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computatrum-text075 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computatrum-text076 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computatrum-dna {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 140px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computatrum-caption07 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description08 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-description09 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-description10 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-caption08 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description11 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-caption09 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description12 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-caption10 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computatrum-description13 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-description14 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-gallery {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: 120px;
}
.computatrum-image6 {
  right: 0px;
  width: 640px;
  bottom: 0px;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.computatrum-image7 {
  width: 760px;
  object-fit: cover;
}
.computatrum-recruit {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computatrum-container4 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computatrum-text083 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  max-width: 1000px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computatrum-description15 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  align-self: flex-start;
  line-height: 54px;
}
.computatrum-sections {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computatrum-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text089 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computatrum-text092 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computatrum-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text093 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computatrum-text096 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computatrum-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text097 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computatrum-text100 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computatrum-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-text103 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computatrum-text104 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computatrum-description16 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computatrum-button1 {
  color: rgb(39, 39, 45);
  font-size: 24px;
  line-height: 30px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(229, 254, 88);
}
.computatrum-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.computatrum-content3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.computatrum-contact {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computatrum-email {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-number {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-links-row {
  gap: 128px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.computatrum-links-column {
  display: flex;
  align-items: flex-start;
  padding-right: 130px;
  flex-direction: column;
  justify-content: flex-start;
}
.computatrum-link01 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link01:hover {
  opacity: 0.7;
}
.computatrum-link02 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link02:hover {
  opacity: 0.7;
}
.computatrum-link03 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link03:hover {
  opacity: 0.7;
}
.computatrum-link04 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link04:hover {
  opacity: 0.7;
}
.computatrum-links-column1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computatrum-link05 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link05:hover {
  opacity: 0.7;
}
.computatrum-link06 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link06:hover {
  opacity: 0.7;
}
.computatrum-link07 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link07:hover {
  opacity: 0.7;
}
.computatrum-link08 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link08:hover {
  opacity: 0.7;
}
.computatrum-link09 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link09:hover {
  opacity: 0.7;
}
.computatrum-link10 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computatrum-link10:hover {
  opacity: 0.7;
}
.computatrum-locations {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.computatrum-location {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title08 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-details07 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computatrum-location1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title09 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-details08 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computatrum-location2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title10 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-details09 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computatrum-location3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computatrum-title11 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computatrum-details10 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
@media(max-width: 991px) {
  .computatrum-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computatrum-text {
    font-size: 32px;
  }
  .computatrum-image {
    display: none;
  }
  .computatrum-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .computatrum-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .computatrum-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-heading {
    gap: var(--dl-space-space-unit);
  }
  .computatrum-text005 {
    font-size: 32px;
  }
  .computatrum-text006 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computatrum-image2 {
    width: 80%;
    height: 100%;
  }
  .computatrum-image3 {
    width: 100%;
  }
  .computatrum-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .computatrum-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .computatrum-description {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-purpose {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-caption {
    font-size: 12px;
  }
  .computatrum-description01 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-purpose1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-caption01 {
    font-size: 12px;
  }
  .computatrum-description02 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-purpose2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-caption02 {
    font-size: 12px;
  }
  .computatrum-description03 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-purpose3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-caption03 {
    font-size: 12px;
  }
  .computatrum-description04 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-purpose4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-caption04 {
    font-size: 12px;
  }
  .computatrum-list {
    grid-gap: 16px;
    grid-template-columns: auto;
  }
  .computatrum-accordion {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text015 {
    font-size: 16px;
  }
  .computatrum-text016 {
    font-size: 16px;
  }
  .computatrum-text017 {
    font-size: 16px;
  }
  .computatrum-text018 {
    font-size: 16px;
  }
  .computatrum-text019 {
    font-size: 16px;
  }
  .computatrum-item {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title01 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text020 {
    font-size: 16px;
  }
  .computatrum-text021 {
    font-size: 16px;
  }
  .computatrum-text022 {
    font-size: 16px;
  }
  .computatrum-text023 {
    font-size: 16px;
  }
  .computatrum-text024 {
    font-size: 16px;
  }
  .computatrum-item1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title02 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text025 {
    font-size: 16px;
  }
  .computatrum-text026 {
    font-size: 16px;
  }
  .computatrum-text027 {
    font-size: 16px;
  }
  .computatrum-text028 {
    font-size: 16px;
  }
  .computatrum-text029 {
    font-size: 16px;
  }
  .computatrum-item2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title03 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text030 {
    font-size: 16px;
  }
  .computatrum-text031 {
    font-size: 16px;
  }
  .computatrum-text032 {
    font-size: 16px;
  }
  .computatrum-text033 {
    font-size: 16px;
  }
  .computatrum-text034 {
    font-size: 16px;
  }
  .computatrum-item3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title04 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text035 {
    font-size: 16px;
  }
  .computatrum-text036 {
    font-size: 16px;
  }
  .computatrum-text037 {
    font-size: 16px;
  }
  .computatrum-text038 {
    font-size: 16px;
  }
  .computatrum-text039 {
    font-size: 16px;
  }
  .computatrum-item4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title05 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text040 {
    font-size: 16px;
  }
  .computatrum-text041 {
    font-size: 16px;
  }
  .computatrum-text042 {
    font-size: 16px;
  }
  .computatrum-text043 {
    font-size: 16px;
  }
  .computatrum-text044 {
    font-size: 16px;
  }
  .computatrum-item5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title06 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text045 {
    font-size: 16px;
  }
  .computatrum-text046 {
    font-size: 16px;
  }
  .computatrum-text047 {
    font-size: 16px;
  }
  .computatrum-text048 {
    font-size: 16px;
  }
  .computatrum-text049 {
    font-size: 16px;
  }
  .computatrum-item6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-title07 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computatrum-text050 {
    font-size: 16px;
  }
  .computatrum-text051 {
    font-size: 16px;
  }
  .computatrum-text052 {
    font-size: 16px;
  }
  .computatrum-text053 {
    font-size: 16px;
  }
  .computatrum-text054 {
    font-size: 16px;
  }
  .computatrum-section {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computatrum-case-studies {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computatrum-caption05 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-text055 {
    font-size: 12px;
  }
  .computatrum-date-range {
    gap: var(--dl-space-space-halfunit);
  }
  .computatrum-text056 {
    font-size: 12px;
  }
  .computatrum-text057 {
    font-size: 12px;
  }
  .computatrum-text058 {
    font-size: 12px;
  }
  .computatrum-testimonials {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
  }
  .computatrum-controls {
    display: none;
  }
  .computatrum-row {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .computatrum-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computatrum-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computatrum-left {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computatrum-text060 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computatrum-description05 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-image4 {
    height: 220px;
  }
  .computatrum-book1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computatrum-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computatrum-left1 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computatrum-text064 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computatrum-description06 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-text068 {
    font-size: 16px;
  }
  .computatrum-image5 {
    height: 220px;
  }
  .computatrum-section1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computatrum-about-us {
    gap: var(--dl-space-space-threeunits);
  }
  .computatrum-header3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computatrum-description07 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-statistics {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .computatrum-text069 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-text070 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-text071 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-text072 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-text073 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-text074 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-text075 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-text076 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-dna {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .computatrum-caption07 {
    font-size: 12px;
  }
  .computatrum-description08 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-description09 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-description10 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-caption08 {
    font-size: 12px;
  }
  .computatrum-description11 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-caption09 {
    font-size: 12px;
  }
  .computatrum-description12 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-caption10 {
    font-size: 12px;
  }
  .computatrum-description13 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-description14 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-recruit {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
  }
  .computatrum-text083 {
    font-size: 32px;
    line-height: 38px;
  }
  .computatrum-description15 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-text086 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-sections {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computatrum-section2 {
    gap: var(--dl-space-space-halfunit);
  }
  .computatrum-text089 {
    font-size: 24px;
    line-height: 30px;
  }
  .computatrum-text092 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-section3 {
    gap: var(--dl-space-space-halfunit);
  }
  .computatrum-text093 {
    font-size: 24px;
    line-height: 30px;
  }
  .computatrum-text096 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-section4 {
    gap: var(--dl-space-space-halfunit);
  }
  .computatrum-text097 {
    font-size: 24px;
    line-height: 30px;
  }
  .computatrum-text100 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-section5 {
    gap: var(--dl-space-space-halfunit);
  }
  .computatrum-text103 {
    font-size: 24px;
    line-height: 30px;
  }
  .computatrum-text104 {
    font-size: 16px;
    line-height: 24px;
  }
  .computatrum-description16 {
    font-size: 16px;
    line-height: 28px;
  }
  .computatrum-text107 {
    font-size: 16px;
    line-height: 20px;
  }
  .computatrum-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computatrum-content3 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computatrum-email {
    font-size: 24px;
  }
  .computatrum-number {
    font-size: 24px;
  }
  .computatrum-links-row {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computatrum-locations {
    padding-top: var(--dl-space-space-threeunits);
    border-color: var(--dl-color-gray-black);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    border-bottom-width: 1px;
  }
  .computatrum-title08 {
    font-size: 24px;
  }
  .computatrum-title09 {
    font-size: 24px;
  }
  .computatrum-title10 {
    font-size: 24px;
  }
  .computatrum-title11 {
    font-size: 24px;
  }
}
@media(max-width: 767px) {
  .computatrum-image1 {
    width: auto;
    height: 220px;
  }
  .computatrum-who {
    margin-bottom: 0px;
  }
  .computatrum-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .computatrum-video1 {
    margin-top: 0px;
  }
  .computatrum-image6 {
    width: 350px;
  }
  .computatrum-image7 {
    width: 350px;
  }
}
@media(max-width: 479px) {
  .computatrum-image3 {
    margin-left: -120px;
  }
  .computatrum-video {
    bottom: -419px;
  }
  .computatrum-image6 {
    width: 240px;
  }
  .computatrum-image7 {
    top: 250px;
    width: 240px;
  }
}
