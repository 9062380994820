.nav-links-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.nav-links-link {
  text-decoration: none;
}
.nav-links-link1 {
  text-decoration: none;
}
.nav-links-link2 {
  text-decoration: none;
}

@media(max-width: 767px) {
  .nav-links-links {
    display: none;
  }
  .nav-links-root-class-name {
    display: flex;
  }
}
