.acceptable-use-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.acceptable-use-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.acceptable-use-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 160px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.acceptable-use-text {
  color: rgb(39, 39, 45);
  width: 100%;
  height: 39px;
  font-size: 54px;
  max-width: 1000px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 400;
}
.acceptable-use-container1 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 40px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  justify-content: flex-start;
}
.acceptable-use-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  border-top-width: 1px;
}
.acceptable-use-description {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.acceptable-use-text04 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text05 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text10 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text11 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text16 {
  font-weight: 600;
}
.acceptable-use-text19 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text22 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text25 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text28 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text31 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text34 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text38 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text39 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text44 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-text47 {
  font-style: normal;
  font-weight: 600;
}
.acceptable-use-navlink {
  text-decoration: underline;
}
.acceptable-use-text52 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text53 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text58 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text59 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text64 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-text65 {
  font-style: normal;
  font-weight: 700;
}
.acceptable-use-link {
  text-decoration: underline;
}
.acceptable-use-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
@media(max-width: 991px) {
  .acceptable-use-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .acceptable-use-text {
    font-size: 32px;
  }
  .acceptable-use-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .acceptable-use-description {
    font-size: 16px;
    line-height: 28px;
  }
  .acceptable-use-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
