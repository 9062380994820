.computa-code-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.computa-code-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.computa-code-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text {
  color: rgb(39, 39, 45);
  width: 100%;
  height: 39px;
  font-size: 54px;
  max-width: 1000px;
  font-weight: 400;
}
.computa-code-text001 {
  font-style: normal;
}
.computa-code-text002 {
  color: #1b1b4c;
  font-weight: 700;
}
.computa-code-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.computa-code-image {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.computa-code-hero-image {
  width: 100%;
  display: none;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #E5FE58;
}
.computa-code-image1 {
  width: 640px;
  height: 540px;
  object-fit: cover;
}
.computa-code-who {
  gap: 120px;
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.computa-code-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.computa-code-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.computa-code-text007 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.computa-code-text008 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.computa-code-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.computa-code-image2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5FE58;
}
.computa-code-image3 {
  object-fit: cover;
  margin-left: -160px;
}
.computa-code-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.computa-code-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  border-top-width: 1px;
}
.computa-code-description {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-description01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-description02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-purpose {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-purpose1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption01 {
  color: rgb(39, 39, 45);
  align-self: flex-end;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-purpose2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption02 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-purpose3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption03 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-purpose4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption04 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-industries {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-caption05 {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-list {
  width: 100%;
  display: grid;
  grid-gap: 80px;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto auto;
}
.computa-code-accordion {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text015 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text016 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text017 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text018 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text019 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text020 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text021 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text022 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text023 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text024 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text025 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text026 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text027 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text028 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text029 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text030 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text031 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text032 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text033 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text034 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text035 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text036 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text037 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text038 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text039 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text040 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text041 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text042 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text043 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text044 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text045 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text046 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text047 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text048 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text049 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-item6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-trigger7 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-text050 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text051 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text052 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text053 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-text054 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-code-description08 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-section {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 135px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 135px;
  background-color: #27272D;
}
.computa-code-case-studies {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: 130px;
  border-bottom-width: 1px;
}
.computa-code-caption06 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.computa-code-text055 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-date-range {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-code-text056 {
  color: rgb(255, 255, 255);
}
.computa-code-text057 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 300;
}
.computa-code-text058 {
  color: rgb(255, 255, 255);
}
.computa-code-testimonials {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  flex-direction: column;
}
.computa-code-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.computa-code-caption07 {
  color: #ffffff;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-controls {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.computa-code-icon {
  fill: #61616B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computa-code-text059 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
}
.computa-code-icon2 {
  fill: #ffffff;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computa-code-row {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.computa-code-quote1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-code-gradiant {
  top: 0px;
  right: 0px;
  width: 100px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  background-image: linear-gradient(90deg, rgba(39, 39, 45, 0) 3.00%,rgba(39, 39, 45, 0.5) 33.00%,rgb(39, 39, 45) 100.00%);
}
.computa-code-book {
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computa-code-content1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computa-code-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text060 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computa-code-description09 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-link {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.computa-code-image4 {
  height: 400px;
  object-fit: cover;
}
.computa-code-book1 {
  width: 100%;
  height: 682px;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computa-code-content2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computa-code-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text066 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computa-code-description10 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-code-form {
  width: 585px;
  height: 231px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-textinput {
  width: 360px;
  height: 34px;
  background-color: rgba(217, 217, 217, 0);
  border-top-width: 0px;
  border-left-width: 00px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.computa-code-container2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computa-code-container3 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computa-code-button {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(0, 0, 0);
}
.computa-code-image5 {
  height: 400px;
  object-fit: cover;
}
.computa-code-section1 {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #244444;
}
.computa-code-about-us {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-header3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-description11 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computa-code-statistics {
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #ffffff;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.computa-code-statistic {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-code-text071 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-code-text072 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-code-statistic1 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-code-text073 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-code-text074 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-code-statistic2 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-code-text075 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-code-text076 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-code-statistic3 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-code-text077 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-code-text078 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-code-dna {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 140px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-code-caption08 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description12 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-description13 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-description14 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-caption09 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description15 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-caption10 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description16 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-caption11 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-code-description17 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-description18 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-gallery {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: 120px;
}
.computa-code-image6 {
  right: 0px;
  width: 640px;
  bottom: 0px;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.computa-code-image7 {
  width: 760px;
  object-fit: cover;
}
.computa-code-recruit {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-code-container4 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-code-text085 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  max-width: 1000px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computa-code-description19 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  align-self: flex-start;
  line-height: 54px;
}
.computa-code-sections {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-code-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text091 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-code-text094 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-code-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text095 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-code-text098 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-code-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text099 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-code-text102 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-code-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-text105 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-code-text106 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-code-description20 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-code-button1 {
  color: rgb(39, 39, 45);
  font-size: 24px;
  line-height: 30px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(229, 254, 88);
}
.computa-code-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.computa-code-content3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.computa-code-contact {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-code-email {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-number {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-links-row {
  gap: 128px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.computa-code-links-column {
  display: flex;
  align-items: flex-start;
  padding-right: 130px;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-code-link01 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link01:hover {
  opacity: 0.7;
}
.computa-code-link02 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link02:hover {
  opacity: 0.7;
}
.computa-code-link03 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link03:hover {
  opacity: 0.7;
}
.computa-code-link04 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link04:hover {
  opacity: 0.7;
}
.computa-code-links-column1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-code-link05 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link05:hover {
  opacity: 0.7;
}
.computa-code-link06 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link06:hover {
  opacity: 0.7;
}
.computa-code-link07 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link07:hover {
  opacity: 0.7;
}
.computa-code-link08 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link08:hover {
  opacity: 0.7;
}
.computa-code-link09 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link09:hover {
  opacity: 0.7;
}
.computa-code-link10 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-code-link10:hover {
  opacity: 0.7;
}
.computa-code-locations {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.computa-code-location {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title08 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-details07 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-code-location1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title09 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-details08 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-code-location2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title10 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-details09 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-code-location3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-code-title11 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-code-details10 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
@media(max-width: 991px) {
  .computa-code-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-code-text {
    font-size: 32px;
  }
  .computa-code-image {
    display: none;
  }
  .computa-code-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .computa-code-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .computa-code-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-heading {
    gap: var(--dl-space-space-unit);
  }
  .computa-code-text007 {
    font-size: 32px;
  }
  .computa-code-text008 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-code-image2 {
    width: 80%;
    height: 100%;
  }
  .computa-code-image3 {
    width: 100%;
  }
  .computa-code-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .computa-code-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .computa-code-description {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-description01 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-description02 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-purpose {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-caption {
    font-size: 12px;
  }
  .computa-code-description03 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-purpose1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-caption01 {
    font-size: 12px;
  }
  .computa-code-description04 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-purpose2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-caption02 {
    font-size: 12px;
  }
  .computa-code-description05 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-purpose3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-caption03 {
    font-size: 12px;
  }
  .computa-code-description06 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-purpose4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-caption04 {
    font-size: 12px;
  }
  .computa-code-description07 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-caption05 {
    font-size: 12px;
  }
  .computa-code-list {
    grid-gap: 16px;
    grid-template-columns: auto;
  }
  .computa-code-accordion {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text015 {
    font-size: 16px;
  }
  .computa-code-text016 {
    font-size: 16px;
  }
  .computa-code-text017 {
    font-size: 16px;
  }
  .computa-code-text018 {
    font-size: 16px;
  }
  .computa-code-text019 {
    font-size: 16px;
  }
  .computa-code-item {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title01 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text020 {
    font-size: 16px;
  }
  .computa-code-text021 {
    font-size: 16px;
  }
  .computa-code-text022 {
    font-size: 16px;
  }
  .computa-code-text023 {
    font-size: 16px;
  }
  .computa-code-text024 {
    font-size: 16px;
  }
  .computa-code-item1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title02 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text025 {
    font-size: 16px;
  }
  .computa-code-text026 {
    font-size: 16px;
  }
  .computa-code-text027 {
    font-size: 16px;
  }
  .computa-code-text028 {
    font-size: 16px;
  }
  .computa-code-text029 {
    font-size: 16px;
  }
  .computa-code-item2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title03 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text030 {
    font-size: 16px;
  }
  .computa-code-text031 {
    font-size: 16px;
  }
  .computa-code-text032 {
    font-size: 16px;
  }
  .computa-code-text033 {
    font-size: 16px;
  }
  .computa-code-text034 {
    font-size: 16px;
  }
  .computa-code-item3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title04 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text035 {
    font-size: 16px;
  }
  .computa-code-text036 {
    font-size: 16px;
  }
  .computa-code-text037 {
    font-size: 16px;
  }
  .computa-code-text038 {
    font-size: 16px;
  }
  .computa-code-text039 {
    font-size: 16px;
  }
  .computa-code-item4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title05 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text040 {
    font-size: 16px;
  }
  .computa-code-text041 {
    font-size: 16px;
  }
  .computa-code-text042 {
    font-size: 16px;
  }
  .computa-code-text043 {
    font-size: 16px;
  }
  .computa-code-text044 {
    font-size: 16px;
  }
  .computa-code-item5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title06 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text045 {
    font-size: 16px;
  }
  .computa-code-text046 {
    font-size: 16px;
  }
  .computa-code-text047 {
    font-size: 16px;
  }
  .computa-code-text048 {
    font-size: 16px;
  }
  .computa-code-text049 {
    font-size: 16px;
  }
  .computa-code-item6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-title07 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-code-text050 {
    font-size: 16px;
  }
  .computa-code-text051 {
    font-size: 16px;
  }
  .computa-code-text052 {
    font-size: 16px;
  }
  .computa-code-text053 {
    font-size: 16px;
  }
  .computa-code-text054 {
    font-size: 16px;
  }
  .computa-code-description08 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-section {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-code-case-studies {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-code-caption06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-text055 {
    font-size: 12px;
  }
  .computa-code-date-range {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-code-text056 {
    font-size: 12px;
  }
  .computa-code-text057 {
    font-size: 12px;
  }
  .computa-code-text058 {
    font-size: 12px;
  }
  .computa-code-testimonials {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
  }
  .computa-code-controls {
    display: none;
  }
  .computa-code-row {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .computa-code-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-code-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computa-code-left {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computa-code-text060 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computa-code-description09 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-image4 {
    height: 220px;
  }
  .computa-code-book1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-code-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computa-code-left1 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computa-code-text066 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computa-code-description10 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-text070 {
    font-size: 16px;
  }
  .computa-code-image5 {
    height: 220px;
  }
  .computa-code-section1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-code-about-us {
    gap: var(--dl-space-space-threeunits);
  }
  .computa-code-header3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-code-description11 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-statistics {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .computa-code-text071 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-text072 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-text073 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-text074 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-text075 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-text076 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-text077 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-text078 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-dna {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .computa-code-caption08 {
    font-size: 12px;
  }
  .computa-code-description12 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-description13 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-description14 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-caption09 {
    font-size: 12px;
  }
  .computa-code-description15 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-caption10 {
    font-size: 12px;
  }
  .computa-code-description16 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-caption11 {
    font-size: 12px;
  }
  .computa-code-description17 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-description18 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-recruit {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
  }
  .computa-code-text085 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-code-description19 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-text088 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-sections {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-code-section2 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-code-text091 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-code-text094 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-section3 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-code-text095 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-code-text098 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-section4 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-code-text099 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-code-text102 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-section5 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-code-text105 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-code-text106 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-code-description20 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-code-text109 {
    font-size: 16px;
    line-height: 20px;
  }
  .computa-code-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-code-content3 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-code-email {
    font-size: 24px;
  }
  .computa-code-number {
    font-size: 24px;
  }
  .computa-code-links-row {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-code-locations {
    padding-top: var(--dl-space-space-threeunits);
    border-color: var(--dl-color-gray-black);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    border-bottom-width: 1px;
  }
  .computa-code-title08 {
    font-size: 24px;
  }
  .computa-code-title09 {
    font-size: 24px;
  }
  .computa-code-title10 {
    font-size: 24px;
  }
  .computa-code-title11 {
    font-size: 24px;
  }
}
@media(max-width: 767px) {
  .computa-code-image1 {
    width: auto;
    height: 220px;
  }
  .computa-code-who {
    margin-bottom: 0px;
  }
  .computa-code-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .computa-code-video1 {
    margin-top: 0px;
  }
  .computa-code-image6 {
    width: 350px;
  }
  .computa-code-image7 {
    width: 350px;
  }
}
@media(max-width: 479px) {
  .computa-code-image3 {
    margin-left: -120px;
  }
  .computa-code-video {
    bottom: -419px;
  }
  .computa-code-image6 {
    width: 240px;
  }
  .computa-code-image7 {
    top: 250px;
    width: 240px;
  }
}
