.testimonial-testimonial {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: #61616B;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  border-right-width: 1px;
}
.testimonial-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial-icon {
  height: 40px;
  object-fit: cover;
}
.testimonial-text {
  color: rgb(255, 255, 255);
  width: 400px;
  font-size: 24px;
  line-height: 44px;
}
.testimonial-author {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.testimonial-author1 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.testimonial-details {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial-name {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.testimonial-origin {
  color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 30px;
}
.testimonial-divider {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}



























@media(max-width: 991px) {
  .testimonial-testimonial {
    gap: var(--dl-space-space-unit);
    margin-right: 0px;
    padding-right: 0px;
    border-right-width: 0px;
  }
  .testimonial-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial-icon {
    height: 24px;
  }
  .testimonial-text {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial-author {
    gap: var(--dl-space-space-unit);
  }
  .testimonial-author1 {
    width: 40px;
    height: 40px;
  }
  .testimonial-details {
    gap: var(--dl-space-space-halfunit);
  }
  .testimonial-name {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial-origin {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial-divider {
    width: 100px;
    height: 1px;
    display: flex;
    background-color: #61616B;
  }
  .testimonial-root-class-name {
    width: 100%;
  }
  .testimonial-root-class-name1 {
    width: 100%;
  }
  .testimonial-root-class-name2 {
    width: 100%;
  }
  .testimonial-root-class-name3 {
    width: 100%;
  }
  .testimonial-root-class-name4 {
    width: 100%;
  }
  .testimonial-root-class-name5 {
    width: 100%;
  }
  .testimonial-root-class-name6 {
    width: 100%;
  }
  .testimonial-root-class-name7 {
    width: 100%;
  }
  .testimonial-root-class-name8 {
    width: 100%;
  }
  .testimonial-root-class-name9 {
    width: 100%;
  }
  .testimonial-root-class-name10 {
    width: 100%;
  }
  .testimonial-root-class-name11 {
    width: 100%;
  }
  .testimonial-root-class-name12 {
    width: 100%;
  }
  .testimonial-root-class-name13 {
    width: 100%;
  }
  .testimonial-root-class-name14 {
    width: 100%;
  }
  .testimonial-root-class-name15 {
    width: 100%;
  }
  .testimonial-root-class-name16 {
    width: 100%;
  }
  .testimonial-root-class-name17 {
    width: 100%;
  }
  .testimonial-root-class-name18 {
    width: 100%;
  }
  .testimonial-root-class-name19 {
    width: 100%;
  }
  .testimonial-root-class-name20 {
    width: 100%;
  }
  .testimonial-root-class-name21 {
    width: 100%;
  }
  .testimonial-root-class-name22 {
    width: 100%;
  }
  .testimonial-root-class-name23 {
    width: 100%;
  }
  .testimonial-root-class-name24 {
    width: 100%;
  }
  .testimonial-root-class-name25 {
    width: 100%;
  }
  .testimonial-root-class-name26 {
    width: 100%;
  }
}
