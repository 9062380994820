.computa-combinator-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.computa-combinator-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.computa-combinator-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 160px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text {
  color: rgb(39, 39, 45);
  width: 100%;
  height: 39px;
  font-size: 54px;
  max-width: 1000px;
  font-weight: 400;
}
.computa-combinator-text001 {
  font-style: normal;
}
.computa-combinator-text002 {
  color: #1b1b4c;
  font-weight: 700;
}
.computa-combinator-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.computa-combinator-image {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
}
.computa-combinator-hero-image {
  width: 100%;
  display: none;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #E5FE58;
}
.computa-combinator-image1 {
  width: 640px;
  height: 540px;
  object-fit: cover;
}
.computa-combinator-who {
  gap: 120px;
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.computa-combinator-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.computa-combinator-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.computa-combinator-text008 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.computa-combinator-text009 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.computa-combinator-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.computa-combinator-image2 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E5FE58;
}
.computa-combinator-image3 {
  object-fit: cover;
  margin-left: -160px;
}
.computa-combinator-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.computa-combinator-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  border-top-width: 1px;
}
.computa-combinator-description {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-description01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-purpose {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-caption {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-purpose1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-caption01 {
  color: rgb(39, 39, 45);
  align-self: flex-end;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-description04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-purpose2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-caption02 {
  color: rgb(39, 39, 45);
  align-self: flex-start;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-industries {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-caption03 {
  color: rgb(39, 39, 45);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-list {
  width: 100%;
  display: grid;
  grid-gap: 80px;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto auto;
}
.computa-combinator-accordion {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text022 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text023 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text024 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text025 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text026 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title01 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text027 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text028 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text029 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text030 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text031 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title02 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text032 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text033 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text034 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text035 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text036 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title03 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text037 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text038 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text039 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text040 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text041 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title04 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text042 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text043 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text044 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text045 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text046 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text047 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text048 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text049 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text050 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text051 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text052 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text053 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text054 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text055 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text056 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-item6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-trigger7 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-text057 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text058 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text059 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text060 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-text061 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 18px;
}
.computa-combinator-section {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 135px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 135px;
  background-color: #27272D;
}
.computa-combinator-case-studies {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: 130px;
  border-bottom-width: 1px;
}
.computa-combinator-caption04 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.computa-combinator-text062 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-date-range {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-combinator-text063 {
  color: rgb(255, 255, 255);
}
.computa-combinator-text064 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 300;
}
.computa-combinator-text065 {
  color: rgb(255, 255, 255);
}
.computa-combinator-testimonials {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  flex-direction: column;
}
.computa-combinator-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.computa-combinator-caption05 {
  color: #ffffff;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-controls {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.computa-combinator-icon {
  fill: #61616B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computa-combinator-text066 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
}
.computa-combinator-icon2 {
  fill: #ffffff;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.computa-combinator-row {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.computa-combinator-quote1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-combinator-gradiant {
  top: 0px;
  right: 0px;
  width: 100px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  background-image: linear-gradient(90deg, rgba(39, 39, 45, 0) 3.00%,rgba(39, 39, 45, 0.5) 33.00%,rgb(39, 39, 45) 100.00%);
}
.computa-combinator-book {
  width: 100%;
  height: 659px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computa-combinator-content1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computa-combinator-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text067 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computa-combinator-description06 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-link {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.computa-combinator-image4 {
  height: 400px;
  object-fit: cover;
}
.computa-combinator-book1 {
  width: 100%;
  height: 682px;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #E5FE58;
}
.computa-combinator-content2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.computa-combinator-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text073 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.computa-combinator-description07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.computa-combinator-form {
  width: 585px;
  height: 231px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-textinput {
  width: 360px;
  height: 34px;
  background-color: rgba(217, 217, 217, 0);
  border-top-width: 0px;
  border-left-width: 00px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.computa-combinator-container2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computa-combinator-container3 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.computa-combinator-button {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(0, 0, 0);
}
.computa-combinator-image5 {
  height: 400px;
  object-fit: cover;
}
.computa-combinator-section1 {
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #244444;
}
.computa-combinator-about-us {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-header3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-description08 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computa-combinator-statistics {
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #ffffff;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.computa-combinator-statistic {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-combinator-text078 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-combinator-text079 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-combinator-statistic1 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-combinator-text080 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-combinator-text081 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-combinator-statistic2 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-combinator-text082 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-combinator-text083 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-combinator-statistic3 {
  gap: var(--dl-space-space-unit);
  width: 272px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-combinator-text084 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  line-height: 65px;
}
.computa-combinator-text085 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: center;
  line-height: 27px;
}
.computa-combinator-dna {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 140px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.computa-combinator-caption06 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description09 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-description10 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-description11 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-caption07 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description12 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-caption08 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description13 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-caption09 {
  color: rgb(255, 255, 255);
  letter-spacing: 5%;
  text-transform: uppercase;
}
.computa-combinator-description14 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-description15 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-gallery {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: row;
  padding-bottom: 120px;
}
.computa-combinator-image6 {
  right: 0px;
  width: 640px;
  bottom: 0px;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.computa-combinator-image7 {
  width: 760px;
  object-fit: cover;
}
.computa-combinator-recruit {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-combinator-container4 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.computa-combinator-text092 {
  color: rgb(255, 255, 255);
  font-size: 54px;
  max-width: 1000px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}
.computa-combinator-description16 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  align-self: flex-start;
  line-height: 54px;
}
.computa-combinator-sections {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.computa-combinator-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text098 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-combinator-text101 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-combinator-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text102 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-combinator-text105 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-combinator-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text106 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-combinator-text109 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-combinator-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-text112 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 1000px;
  line-height: 36px;
}
.computa-combinator-text113 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 1000px;
  line-height: 27px;
}
.computa-combinator-description17 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  line-height: 54px;
}
.computa-combinator-button1 {
  color: rgb(39, 39, 45);
  font-size: 24px;
  line-height: 30px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  background-color: rgb(229, 254, 88);
}
.computa-combinator-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.computa-combinator-content3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.computa-combinator-contact {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-combinator-email {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-number {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-links-row {
  gap: 128px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.computa-combinator-links-column {
  display: flex;
  align-items: flex-start;
  padding-right: 130px;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-combinator-link01 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link01:hover {
  opacity: 0.7;
}
.computa-combinator-link02 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link02:hover {
  opacity: 0.7;
}
.computa-combinator-link03 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link03:hover {
  opacity: 0.7;
}
.computa-combinator-link04 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link04:hover {
  opacity: 0.7;
}
.computa-combinator-links-column1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.computa-combinator-link05 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link05:hover {
  opacity: 0.7;
}
.computa-combinator-link06 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link06:hover {
  opacity: 0.7;
}
.computa-combinator-link07 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link07:hover {
  opacity: 0.7;
}
.computa-combinator-link08 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link08:hover {
  opacity: 0.7;
}
.computa-combinator-link09 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link09:hover {
  opacity: 0.7;
}
.computa-combinator-link10 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.computa-combinator-link10:hover {
  opacity: 0.7;
}
.computa-combinator-locations {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.computa-combinator-location {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title08 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-details07 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-combinator-location1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title09 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-details08 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-combinator-location2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title10 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-details09 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
.computa-combinator-location3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.computa-combinator-title11 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.computa-combinator-details10 {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
@media(max-width: 991px) {
  .computa-combinator-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-combinator-text {
    font-size: 32px;
  }
  .computa-combinator-image {
    display: none;
  }
  .computa-combinator-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .computa-combinator-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .computa-combinator-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-heading {
    gap: var(--dl-space-space-unit);
  }
  .computa-combinator-text008 {
    font-size: 32px;
  }
  .computa-combinator-text009 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-combinator-image2 {
    width: 80%;
    height: 100%;
  }
  .computa-combinator-image3 {
    width: 100%;
  }
  .computa-combinator-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .computa-combinator-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .computa-combinator-description {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-description01 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-purpose {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-caption {
    font-size: 12px;
  }
  .computa-combinator-description02 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-purpose1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-caption01 {
    font-size: 12px;
  }
  .computa-combinator-description03 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-description04 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-purpose2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-caption02 {
    font-size: 12px;
  }
  .computa-combinator-description05 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-caption03 {
    font-size: 12px;
  }
  .computa-combinator-list {
    grid-gap: 16px;
    grid-template-columns: auto;
  }
  .computa-combinator-accordion {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text022 {
    font-size: 16px;
  }
  .computa-combinator-text023 {
    font-size: 16px;
  }
  .computa-combinator-text024 {
    font-size: 16px;
  }
  .computa-combinator-text025 {
    font-size: 16px;
  }
  .computa-combinator-text026 {
    font-size: 16px;
  }
  .computa-combinator-item {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title01 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text027 {
    font-size: 16px;
  }
  .computa-combinator-text028 {
    font-size: 16px;
  }
  .computa-combinator-text029 {
    font-size: 16px;
  }
  .computa-combinator-text030 {
    font-size: 16px;
  }
  .computa-combinator-text031 {
    font-size: 16px;
  }
  .computa-combinator-item1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title02 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text032 {
    font-size: 16px;
  }
  .computa-combinator-text033 {
    font-size: 16px;
  }
  .computa-combinator-text034 {
    font-size: 16px;
  }
  .computa-combinator-text035 {
    font-size: 16px;
  }
  .computa-combinator-text036 {
    font-size: 16px;
  }
  .computa-combinator-item2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title03 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text037 {
    font-size: 16px;
  }
  .computa-combinator-text038 {
    font-size: 16px;
  }
  .computa-combinator-text039 {
    font-size: 16px;
  }
  .computa-combinator-text040 {
    font-size: 16px;
  }
  .computa-combinator-text041 {
    font-size: 16px;
  }
  .computa-combinator-item3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title04 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text042 {
    font-size: 16px;
  }
  .computa-combinator-text043 {
    font-size: 16px;
  }
  .computa-combinator-text044 {
    font-size: 16px;
  }
  .computa-combinator-text045 {
    font-size: 16px;
  }
  .computa-combinator-text046 {
    font-size: 16px;
  }
  .computa-combinator-item4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title05 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text047 {
    font-size: 16px;
  }
  .computa-combinator-text048 {
    font-size: 16px;
  }
  .computa-combinator-text049 {
    font-size: 16px;
  }
  .computa-combinator-text050 {
    font-size: 16px;
  }
  .computa-combinator-text051 {
    font-size: 16px;
  }
  .computa-combinator-item5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title06 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text052 {
    font-size: 16px;
  }
  .computa-combinator-text053 {
    font-size: 16px;
  }
  .computa-combinator-text054 {
    font-size: 16px;
  }
  .computa-combinator-text055 {
    font-size: 16px;
  }
  .computa-combinator-text056 {
    font-size: 16px;
  }
  .computa-combinator-item6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-title07 {
    font-size: 32px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text057 {
    font-size: 16px;
  }
  .computa-combinator-text058 {
    font-size: 16px;
  }
  .computa-combinator-text059 {
    font-size: 16px;
  }
  .computa-combinator-text060 {
    font-size: 16px;
  }
  .computa-combinator-text061 {
    font-size: 16px;
  }
  .computa-combinator-section {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-combinator-case-studies {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-combinator-caption04 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-text062 {
    font-size: 12px;
  }
  .computa-combinator-date-range {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text063 {
    font-size: 12px;
  }
  .computa-combinator-text064 {
    font-size: 12px;
  }
  .computa-combinator-text065 {
    font-size: 12px;
  }
  .computa-combinator-testimonials {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
  }
  .computa-combinator-controls {
    display: none;
  }
  .computa-combinator-row {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .computa-combinator-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-combinator-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computa-combinator-left {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computa-combinator-text067 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computa-combinator-description06 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-image4 {
    height: 220px;
  }
  .computa-combinator-book1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-combinator-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .computa-combinator-left1 {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .computa-combinator-text073 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .computa-combinator-description07 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-text077 {
    font-size: 16px;
  }
  .computa-combinator-image5 {
    height: 220px;
  }
  .computa-combinator-section1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .computa-combinator-about-us {
    gap: var(--dl-space-space-threeunits);
  }
  .computa-combinator-header3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .computa-combinator-description08 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-statistics {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .computa-combinator-text078 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-text079 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-text080 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-text081 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-text082 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-text083 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-text084 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-text085 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-dna {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .computa-combinator-caption06 {
    font-size: 12px;
  }
  .computa-combinator-description09 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-description10 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-description11 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-caption07 {
    font-size: 12px;
  }
  .computa-combinator-description12 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-caption08 {
    font-size: 12px;
  }
  .computa-combinator-description13 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-caption09 {
    font-size: 12px;
  }
  .computa-combinator-description14 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-description15 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-recruit {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
  }
  .computa-combinator-text092 {
    font-size: 32px;
    line-height: 38px;
  }
  .computa-combinator-description16 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-text095 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-sections {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-combinator-section2 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text098 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-combinator-text101 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-section3 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text102 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-combinator-text105 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-section4 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text106 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-combinator-text109 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-section5 {
    gap: var(--dl-space-space-halfunit);
  }
  .computa-combinator-text112 {
    font-size: 24px;
    line-height: 30px;
  }
  .computa-combinator-text113 {
    font-size: 16px;
    line-height: 24px;
  }
  .computa-combinator-description17 {
    font-size: 16px;
    line-height: 28px;
  }
  .computa-combinator-text116 {
    font-size: 16px;
    line-height: 20px;
  }
  .computa-combinator-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-combinator-content3 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .computa-combinator-email {
    font-size: 24px;
  }
  .computa-combinator-number {
    font-size: 24px;
  }
  .computa-combinator-links-row {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .computa-combinator-locations {
    padding-top: var(--dl-space-space-threeunits);
    border-color: var(--dl-color-gray-black);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    border-bottom-width: 1px;
  }
  .computa-combinator-title08 {
    font-size: 24px;
  }
  .computa-combinator-title09 {
    font-size: 24px;
  }
  .computa-combinator-title10 {
    font-size: 24px;
  }
  .computa-combinator-title11 {
    font-size: 24px;
  }
}
@media(max-width: 767px) {
  .computa-combinator-image1 {
    width: auto;
    height: 220px;
  }
  .computa-combinator-who {
    margin-bottom: 0px;
  }
  .computa-combinator-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .computa-combinator-video1 {
    margin-top: 0px;
  }
  .computa-combinator-image6 {
    width: 350px;
  }
  .computa-combinator-image7 {
    width: 350px;
  }
}
@media(max-width: 479px) {
  .computa-combinator-image3 {
    margin-left: -120px;
  }
  .computa-combinator-video {
    bottom: -419px;
  }
  .computa-combinator-image6 {
    width: 240px;
  }
  .computa-combinator-image7 {
    top: 250px;
    width: 240px;
  }
}
