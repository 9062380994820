.navbar-navbar {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}
.navbar-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
}
.navbar-navlink {
  display: contents;
}
.navbar-branding {
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  text-decoration: none;
  border-bottom-width: 1px;
}
.navbar-desktop-menu {
  display: flex;
}
.navbar-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-right {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.navbar-button {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navbar-work-with-us {
  cursor: pointer;
  display: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(229, 254, 88);
}
.navbar-work-with-us:hover {
  opacity: 0.5;
}
.navbar-text1 {
  display: flex;
  font-size: 24px;
}
.navbar-image {
  width: 100px;
  display: none;
  object-fit: cover;
}
.navbar-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-work-with-us1 {
  cursor: pointer;
  display: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(229, 254, 88);
}
.navbar-work-with-us1:hover {
  opacity: 0.5;
}
.navbar-text2 {
  display: flex;
  font-size: 24px;
}
.navbar-image1 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbar-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-branding1 {
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  border-color: #61616B;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.navbar-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-middle {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.navbar-link {
  text-decoration: none;
}
.navbar-link1 {
  text-decoration: none;
}
.navbar-link2 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .navbar-navbar-interactive {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .navbar-text1 {
    line-height: 30px;
  }
  .navbar-text2 {
    line-height: 30px;
  }
}
@media(max-width: 767px) {
  .navbar-navbar-interactive {
    padding-top: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .navbar-desktop-menu {
    display: none;
  }
  .navbar-work-with-us {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .navbar-text1 {
    display: none;
  }
  .navbar-image {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .navbar-burger-menu {
    display: flex;
    align-self: flex-start;
  }
  .navbar-work-with-us1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .navbar-text2 {
    display: none;
  }
  .navbar-image1 {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .navbar-branding1 {
    max-width: 80%;
  }
}
@media(max-width: 479px) {
  .navbar-text {
    font-size: 2em;
    font-style: normal;
    font-weight: 500;
  }
  .navbar-text2 {
    display: none;
  }
  .navbar-mobile-menu {
    padding: 16px;
  }
  .navbar-text3 {
    font-size: 2em;
    font-style: normal;
    font-weight: 500;
  }
}
