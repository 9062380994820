.footer-header {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #61616B;
  border-width: 1px;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.footer-branding {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.footer-socials {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-link {
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-link2 {
  display: contents;
}
.footer-icon {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.footer-link3 {
  display: contents;
}
.footer-link4 {
  display: contents;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer-container2 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.footer-navlink {
  color: #111;
  text-decoration: none;
}
.footer-navlink1 {
  color: #111;
  text-decoration: none;
}
.footer-link5 {
  color: #111;
  text-decoration: none;
}
.footer-container3 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.footer-navlink2 {
  color: #111;
  text-decoration: none;
}
.footer-link6 {
  color: #111;
  text-decoration: none;
}
.footer-link7 {
  color: #111;
  text-decoration: none;
}
.footer-container4 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.footer-navlink3 {
  color: #111;
  text-decoration: none;
}
.footer-link8 {
  color: #111;
  text-decoration: none;
}
.footer-text {
  color: var(--dl-color-gray-500);
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
}
@media(max-width: 991px) {
  .footer-header {
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .footer-container {
    flex-wrap: wrap;
  }
  .footer-branding {
    font-size: 18px;
  }
  .footer-socials {
    flex-wrap: wrap;
  }
}
@media(max-width: 479px) {
  .footer-container1 {
    flex-wrap: wrap;
  }
  .footer-container2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-container3 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-container4 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
